<template>
  <WeCard>
    <h5 class="custom-card-title">
      <i class="fas fa-truck"></i>
      <span>KARGO BİLGİLERİ</span>
    </h5>
    <hr />

    <!-- Width & Height & Depth -->
    <div class="form-row">
      <!-- Width -->
      <div class="col-12 col-md">
        <WeInput
          v-model="computedData.width"
          v-bind:filter-number="true"
          name="width"
          v-bind:prepend="$t('width')"
        />
      </div>
      <!-- ./Width -->

      <!-- Height -->
      <div class="col-12 col-md">
        <WeInput
          v-model="computedData.height"
          v-bind:filter-number="true"
          name="height"
          v-bind:prepend="$t('height')"
        />
      </div>
      <!-- ./Height -->

      <!-- Depth -->
      <div class="col-12 mb-3 col-md mb-md-0">
        <WeInput
          v-model="computedData.depth"
          v-bind:filter-number="true"
          name="depth"
          v-bind:prepend="$t('depth')"
        />
      </div>
      <!-- ./Depth -->
    </div>
    <!-- ./Width & Height & Depth -->

    <!-- Weight & Desi -->
    <div class="form-row">
      <!-- Weight -->
      <div class="col-12 col-md">
        <WeInput
          v-model="computedData.weight"
          v-bind:filter-number="true"
          name="weight"
          v-bind:prepend="$t('weight')"
        />
      </div>
      <!-- .Weight -->

      <!-- Desi -->
      <div class="col-12 mb-3 col-md mb-md-0">
        <WeInput
          v-model="computedData.desi"
          v-bind:filter-number="true"
          name="desi"
          v-bind:prepend="$t('desi')"
        />
      </div>
      <!-- ./Desi -->

      <!-- Price -->
      <div class="col-12 col-md">
        <WePriceInput
          v-model="computedData.price"
          name="price"
          v-bind:prepend="$t('price')"
          append="₺"
        />
      </div>
      <!-- ./Price -->
    </div>
    <!-- ./Weight & Desi -->
  </WeCard>
</template>
<script>
export default {
  name: "WeCargoForm",
  props: {
    data: null,
  },
  model: {
    prop: "data",
  },
  computed: {
    computedData() {
      return this.data;
    },
  },
};
</script>
